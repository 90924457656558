import { useRuntimeConfig } from "#app";
import { runtimeConfigSchema } from "../build-schemas";

import type { MalarkeyModuleOptions } from "../build-schemas";

export const useUseMalarkeyConfig = () => {
  const runtimeConfig = useRuntimeConfig();
  const { error, value: validatedRuntimeConfig } =
    runtimeConfigSchema.validate(runtimeConfig);
  if (error) {
    throw error;
  }
  const { malarkey } = validatedRuntimeConfig.public as {
    malarkey: MalarkeyModuleOptions;
  };
  return malarkey as MalarkeyModuleOptions;
};
